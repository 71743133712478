import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: #FEFBF5;
    /* background: #a6ddff; */
    /* background: #dbf1ff; */
    font-family: Open-Sans, Helvetica, Sans-Serif;
  }
`

export default GlobalStyle

export const cardTextLight = {
  title: "white",
  text: "#eee",
}

export const cardTextDark = {
  title: "#333",
  text: "#333",
}

export default {
  heroMobileWidth: "940px",
  projectGridBreakpoint: "800px",
  primary: "#333",
  cardTextTheme: cardTextLight,
  themeColors: {
    greyDeep: "#333333",
    greyLight: "#EEEEEE",
  },
  cardColors: {
    cardGreen: "#82C4C3",
    cardRed: "#D45079",
    cardPink: "#FFB6B6",
    cardGrey: "#717d84",
    cardGreyLight: "#EEEEEE",
    cardGreyMid: "#4d4d4d",
    cardGreyDeep: "#292929",
    cardBeige: "#eee6d5",
    cardBlue: "#6886C5",
    cardBlue2: "#2D7F9D",
    cardOrange: "#ffba92",
    cardPurple: "#8c96c9",
    cardMaroon: "#752b5e",
    cardBlueLight: "#a6ddff",
    cardHenna: "#b95358",
    cardBlueVeryLight: "#d7e9fc",
    ipccBlue: "#5492cd",
    octopusPink: "#4d1549",
    octopusBlue: "#040F27",
    ccsTheme: "#0e044d",
    distinguishedDevsPrimary: "#00517c",
  },
}
